import React from 'react';
import Grid from '@mui/material/Grid';

export default function WinProbability() {
  return (
    <div id="wp-app">
      <Grid
        container
        direction="column"
      >
        <Grid item>
          Select button
        </Grid>

        <Grid item>
          Graph wp
        </Grid>
      </Grid>
    </div>
  );
}
